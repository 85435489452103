import React, { FC, useState } from 'react';
import { useLocation } from '@reach/router';
import { omit } from 'lodash';
import { LocaleLink } from '../links/LocaleLink';
import classNames from 'classnames';
import { Ul, Div, Li, Span, Nav, Button, makeStyledComponent, H5 } from '../Elements/index';
import { Icon } from '../Icon';
import { Page } from '../../../common/fragments/Page';
import { useLocale } from '../../../common/hooks/useLocale';
import { ContentfulBladeSubNav } from '../../../contentful/content-types/blades/blade-sub-nav';
import { ContentfulBladeSubNavMenuItem } from '../../../contentful/content-types/blades/blade-sub-nav-menu-item';
import { RelatedBlade } from '../../../contentful/blades';
import { isAbsoluteURL, isDirectLink, pathJoin } from '../../../common/util/path';

export function isContentfulBladeSubNav(blade: RelatedBlade): blade is ContentfulBladeSubNav {
  return blade.contentTypeId === 'bladeSubNav';
}

interface SubNavBladeProps {
  blade: ContentfulBladeSubNav;
  pagePrefix: string;
}

export const BladeSubNav: FC<SubNavBladeProps> = ({ blade, pagePrefix }) => {
  return (
    <>
      <Div
        display={['none', 'block']}
        border="bottom"
        borderColor="gray2"
        className={blade.stickyNav ? 'sticky-nav' : ''}
        backgroundColor="white"
      >
        <Nav width="100%" center maxWidth={9} display={'flex'} justifyContent="between">
          <Div display={'flex'} justifyContent="between" className="pl3">
            <H5
              className="f3"
              display="flex"
              alignItems="center"
              aria-label="Cloudflare For Teams Title"
            >
              {blade.menuTitle}
            </H5>

            <Ul
              display="flex"
              marginVertical={0}
              paddingLeft={3}
              className="list nowrap overflow-visible"
            >
              {blade.menuItems.map(item => (
                <ListLinkItem
                  key={`ListLinkItem-${item.contentfulId}`}
                  item={item}
                  pagePrefix={pagePrefix}
                />
              ))}
            </Ul>
            <Ul
              display="flex"
              marginVertical={0}
              paddingLeft={3}
              className="list nowrap overflow-visible"
            >
              {blade.rightMenuItems?.map(item => (
                <ListLinkItem
                  key={`ListLinkItem-${item.contentfulId}`}
                  item={item}
                  pagePrefix={pagePrefix}
                  rightLink={true}
                />
              ))}
            </Ul>
          </Div>
        </Nav>
      </Div>
      <MobileNav blade={blade} pagePrefix={pagePrefix} />
    </>
  );
};

interface ListLinkItemProps {
  active?: boolean;
  className?: string;
  item: ContentfulBladeSubNavMenuItem;
  pagePrefix: string;
  rightLink?: boolean;
}

const ListLinkItem: FC<ListLinkItemProps> = ({ item, active, pagePrefix, rightLink = false }) => {
  const locale = useLocale() || 'en-US';
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const href = getHrefFromMenuItem(item);
  const activeBasedLocation = isActiveBasedOnLocation(item, location.pathname, pagePrefix, locale);
  return typeof href !== 'string' ? (
    <li
      className={classNames('hover-highlight-link dib flex items-center pointer relative', {
        active: active || activeBasedLocation,
      })}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Span
        display="flex"
        alignItems="center"
        paddingVertical={2}
        paddingHorizontal={3}
        fontSize={1}
        fontWeight={6}
        color="brandGray5"
      >
        {item.linkText}
      </Span>
      {open && Array.isArray(item.subMenuItems) && item.subMenuItems.length > 0 && (
        <div
          className="absolute z-max top-100 mw-8"
          style={{ marginTop: '1px', minWidth: '100px', marginLeft: '12px' }}
        >
          <Ul
            backgroundColor="white"
            marginTop={0}
            paddingHorizontal={0}
            paddingVertical={1}
            className="list o-1 shadow-5"
          >
            {(item.subMenuItems || []).map(subItem => {
              const href = getHrefFromMenuItem(subItem);

              return (
                <li key={`ListLinkItem-li-${subItem.contentfulId}`}>
                  {href ? (
                    <LocaleLink
                      to={href}
                      variant={isAbsoluteURL(href) ? 'anchor' : 'link'}
                      display="block"
                      paddingVertical={1}
                      paddingHorizontal={2}
                      fontSize={1}
                      className="black subnav-item"
                    >
                      {subItem.linkText}
                    </LocaleLink>
                  ) : (
                    <Span
                      display="block"
                      paddingVertical={1}
                      paddingHorizontal={2}
                      fontSize={1}
                      className="black subnav-item"
                    >
                      {subItem.linkText}
                    </Span>
                  )}
                </li>
              );
            })}
          </Ul>
        </div>
      )}
    </li>
  ) : (
    <li
      className={classNames('flex items-center link hover-highlight-link dib', {
        active: active || activeBasedLocation,
      })}
    >
      <div className="flex justify-between">
        <LocaleLink
          to={href}
          variant={isDirectLink(href) ? 'anchor' : 'link'}
          display="flex"
          alignItems="center"
          paddingVertical={2}
          paddingHorizontal={3}
          fontSize={1}
          fontWeight={6}
          color={rightLink ? 'br-blue' : 'brandGray5'}
        >
          {item.linkText}
        </LocaleLink>
      </div>
    </li>
  );
};

const MobileNav: React.FC<SubNavBladeProps> = ({ blade, pagePrefix }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const locale = useLocale() || 'en-US';

  const activeTab = getActiveTab(pagePrefix, location.pathname, blade.menuItems, locale);

  return (
    <Nav className={blade.stickyNav ? 'sticky-nav--mobile' : ''} backgroundColor="white">
      <Div
        role="button"
        aria-expanded={isOpen ? 'true' : 'false'}
        tabIndex={0}
        display={['flex', 'none']}
        justifyContent="between"
        border="bottom"
        borderColor="gray5"
        paddingHorizontal={3}
        paddingVertical={2}
        onClick={() => setIsOpen(!isOpen)}
        onKeyPress={e => {
          if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
            setIsOpen(!isOpen);
            e.preventDefault();
          }
        }}
      >
        <Div fontSize={1} fontWeight={7}>
          {activeTab ? activeTab.linkText : blade.menuTitle ? blade.menuTitle : ''}
        </Div>
        <Div>
          <Icon fill="gray5" type="caret-down" rotate={isOpen ? 0 : 270} />
        </Div>
      </Div>
      <Div
        display={isOpen ? 'block' : 'none'}
        shadow={3}
        position="absolute"
        width="100%"
        backgroundColor="white"
        zIndex={2}
      >
        <Ul paddingLeft={0} marginVertical={0} className="list">
          {blade.menuItems.map(item => (
            <MobileMenuItem key={`mobile-nav-item-1-${item.contentfulId}`} item={item} />
          ))}

          {blade.rightMenuItems?.map(item => (
            <MobileMenuItem key={`mobile-nav-item-1-${item.contentfulId}`} item={item} />
          ))}
        </Ul>
      </Div>
    </Nav>
  );
};

interface MobileMenuItemProps {
  item: ContentfulBladeSubNavMenuItem;
}

const ButtonOrLink = makeStyledComponent<{
  className: string;
  variant: 'anchor' | 'link';
  to: string | null;
  onClick: React.ButtonHTMLAttributes<'button'>['onClick'];
}>(props => {
  return props.to ? (
    <LocaleLink {...omit(props, 'onClick')} variant={props.variant} to={props.to}></LocaleLink>
  ) : (
    <Button backgroundColor="transparent" border="none" {...props}></Button>
  );
});

const MobileMenuItem: FC<MobileMenuItemProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const href = getHrefFromMenuItem(item);

  return (
    <Li marginVertical={0} marginHorizontal={0}>
      <ButtonOrLink
        to={href}
        variant={isDirectLink(href) ? 'anchor' : 'link'}
        onClick={e => setIsOpen(!isOpen)}
        display="flex"
        width="100%"
        paddingHorizontal={3}
        paddingVertical={2}
        fontSize={1}
        color="black"
        textAlign="left"
      >
        <Span flex="auto">{item.linkText}</Span>
        {item.subMenuItems && (
          <Span>
            <Icon fill="gray5" type="caret-down" rotate={isOpen ? 0 : 270} />
          </Span>
        )}
      </ButtonOrLink>
      {isOpen && (
        <Ul className="list" paddingLeft={4}>
          {item.subMenuItems?.map(item => (
            <MobileMenuItem key={`MobileMenuItem-${item.contentfulId}`} item={item} />
          ))}
        </Ul>
      )}
    </Li>
  );
};

function getActiveTab(
  pagePrefix: string,
  pathname: string,
  tabs: ContentfulBladeSubNav['menuItems'],
  locale: string = 'en-US'
) {
  for (let i = 0; i < tabs.length; i++) {
    if (isActiveBasedOnLocation(tabs[i], pathname, pagePrefix, locale)) {
      return tabs[i];
    }
  }
}

type SubNavItemForActiveBasedOnLocation = Pick<ContentfulBladeSubNavMenuItem, 'url'> & {
  subMenuItems?: SubNavItemForActiveBasedOnLocation[];
  page?: Pick<Page, 'relativePath'>;
};

export function isActiveBasedOnLocation(
  item: SubNavItemForActiveBasedOnLocation,
  pathname: string,
  pagePrefix: string,
  locale: string
): boolean {
  if (Array.isArray(item.subMenuItems)) {
    for (let i = 0; i < item.subMenuItems.length; i++) {
      const subMenuItem = item.subMenuItems[i];

      if (isActiveBasedOnLocation(subMenuItem, pathname, pagePrefix, locale)) {
        return true;
      }
    }
  }

  const selectedLocale = locale.toLocaleLowerCase() === 'en-us' ? '' : locale.toLocaleLowerCase();

  if (item.page && item.page.relativePath) {
    return (
      pathJoin('/', selectedLocale, item.page.relativePath, '/') === pathJoin('/', pathname, '/')
    );
  }
  if (item.url) {
    if (isAbsoluteURL(item.url)) {
      return false;
    }
    return (
      pathJoin('/', selectedLocale, item.url, '/')?.replace('//', '/') ===
      pathJoin('/', pathname, '/')
    );
  }
  return false;
}

export function getHrefFromMenuItem(
  item: Pick<ContentfulBladeSubNavMenuItem, 'url'> & { page?: Pick<Page, 'relativePath'> }
): string | null {
  let href: string | null = null;
  const url = item.url;

  if (item.page && item.page.relativePath) {
    href = pathJoin(item.page.relativePath || '');
  } else if (url) {
    href = isDirectLink(url) ? url : pathJoin('/', url || '');
  }

  return href;
}
